import React from 'react';
import customStyles from "../styles/custom.module.css"
import {Link} from "gatsby"

export default function CustomHeading({title}){
    return <div className={customStyles.main}>
        <Link to="/about" style={{textDecoration:"none"}}>
            <p className={customStyles.heading}>{title}</p>
        </Link>
        
    </div>
}