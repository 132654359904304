import React from 'react'
import {Link} from "gatsby"
import backStyles from "../styles/back.module.css"

export default function BackButton(){

    return <div style={{ textAlign: "center" }}>
    <Link to="/" style={{textDecoration:"none"}} >
        

        <p className={backStyles.back} style={{ textAlign: "left", display: "inline-block" }}>
           {"<"} Back
        </p>
            
    </Link>
        </div>
 
}