import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
// import Layout from "../components/layout"
import containerStyles from "../styles/container.module.css"
import Head from "../components/head"
import Footer from "../components/footer";
import CustomHeading from "../components/customHeading";
// import Header from "../components/header";
import BackButton from "../components/backButton";
export default function About() {
    // const data = useStaticQuery(graphql`
    // query{
    //     site{
    //         siteMetadata{
    //             title,author
    //         }
    //     }
    // }
    
    // `)
    return <div >
        <Head title="About" />
        
        
        <CustomHeading title="About" />
        <BackButton/>
        {/* <Layout > */}
            <div className={containerStyles.container}>
            <p> Hey there! </p>
                <p >
                I have been in tech industry for more than a year now. Most of my work is based around developing Android/iOS apps using Flutter, SwiftUI. I keep on learning new stuff. Interested in AR/VR.
            </p>
            <p>
                <i>Side note:</i>   I love music and playing guitar. Any John Mayer fans?
            </p>
            <p> Let me know on Instagram or email if you have any questions, comments, remark or anything!

            </p>
            </div>
            <Footer/>
        {/* </Layout> */}
      
        </div>


}